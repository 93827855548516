import React, {useCallback, useState} from 'react';
import {RouteComponentProps, withRouter} from "react-router-dom";
import RouteService from "../services/route-service";
import FlexStack from "../components/Common/flex-stack";
import {SearchField} from "../components/Common";
import {useTranslation} from "react-i18next";
import {BreederSearchResult} from "../api/generated/herd-breeder";
import {FilteredBreeders} from "../components/Breeder/filtered-breeders";


const Breeders: React.FC<RouteComponentProps> = ({history}) => {
    const {t} = useTranslation();
    const [searchTerm, setSearchTerm] = useState('')

    const performSearch = useCallback(async (searchTerm: string): Promise<BreederSearchResult[]> => {
        setSearchTerm(searchTerm);
        return []; //we don't show suggestions on the searchfield here, so [] is returned
    }, []);

    const handleBreederClick = (breeder: BreederSearchResult) => {
        history.push({pathname: RouteService.expand(RouteService.BREEDER_DETAILS, {breederId: breeder.id})});
    };

    return (
        <FlexStack>
            <SearchField<BreederSearchResult> label={t("breeders.filter")}
                                              findSuggestions={performSearch}
                                              value={null}
            />
            <FilteredBreeders onBreederClick={handleBreederClick} searchTerm={searchTerm}/>
        </FlexStack>
    );
};

export default withRouter(Breeders);
