// @flow
import React, {FunctionComponent, PropsWithChildren} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

type ConfirmDialogProps = PropsWithChildren<{
    title?: string
    cancelButtonText?: string
    okButtonText?: string
    onClose: () => void,
    onOk: () => void,
    onCancel?: () => void,
    okButtonDisabled?: boolean,
    open: boolean,
}>;

const ConfirmDialog: FunctionComponent<ConfirmDialogProps> = ({
                                                                  title = "Bitte bestätige deine Auswahl",
                                                                  cancelButtonText = "Abbrechen",
                                                                  okButtonText = "Ok",
                                                                  onClose,
                                                                  open,
                                                                  onOk,
                                                                  onCancel,
                                                                  okButtonDisabled = false,
                                                                  children
                                                              }) => {

    const handleOkClick = () => {
        onClose();
        onOk();
    }

    const handleCancelClick = () => {
        onClose();
        if (onCancel) {
            onCancel();
        }
    }

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelClick}>{cancelButtonText}</Button>
                <Button disabled={okButtonDisabled} onClick={handleOkClick} variant="contained">{okButtonText}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog