// @flow
import React, {FunctionComponent, useCallback, useState} from 'react';
import {Box, Button, Chip, Stack} from "@mui/material";
import TabularTextCard from "./tabular-text-card";
import {useParams} from "react-router-dom";
import RegistrationReviewService from "../../services/registration-review-service";
import ExistingRegistrationsCard from "./existing-registrations-card";
import ReviewSimilaritiesCard from "./review-similarities-card";
import CommentCard from "./comment-card";
import OnCloseNavigationSnackbar from "../Common/on-close-navigation-snackbar";
import RouteService from "../../services/route-service";
import FlexStack from "../Common/flex-stack";
import {AnimalService} from "../../services/animal-service";
import {RegistrationService} from "../../services/registration-service";
import {JsonAnimal} from "../../api/generated/rest-dto";
import PedigreeCard from "../AnimalDashboard/Pedigree/pedigree-card";
import {useTranslation} from "react-i18next";
import FlexCard from "./flex-card";
import RenderOnRole from "../Permissions/render-on-role";
import {PANON_ADMIN, REGISTRY_WARDEN} from "../Permissions/Roles";
import {useSuspenseQuery} from "@tanstack/react-query";

type AnimalReviewPathParams = {
    id: string;
};

const ACCEPTED_MESSAGE = 'Registrierung akzeptiert';
const REJECTED_MESSAGE = 'Registrierung abgelehnt';

const useReviewRequest = (reviewRequestId: string) => {
    const {data} = useSuspenseQuery({
        queryKey: ['review-request', reviewRequestId],
        queryFn: async () => {
            if (!reviewRequestId) {
                return {
                    reviewRequest: undefined,
                    similarAnimals: [],
                    animal: undefined,
                    registrations: [],
                };
            }

            const reviewRequest = await RegistrationService.getPendingRequest(reviewRequestId);
            const similarAnimals = await RegistrationReviewService.findRegisteredSimilarAnimals(reviewRequestId);
            const animal = await AnimalService.loadJsonAnimalByPanonId(reviewRequest.animal.id); //animal.id is a panonId
            const registrations = await RegistrationService.findRegistrationsByPanonId(reviewRequest.animal.id);

            return {
                reviewRequest,
                similarAnimals,
                animal,
                registrations,
            };
        },
    });

    return data;
};

const StatusCard = (props: { rejected: boolean; breeder: string }) => (
    <FlexCard title="Anfragestatus">
        <Stack direction="column" spacing={1}>
            <Box>Eingereicht von <b>{props.breeder}</b></Box>
            <Box>
                {props.rejected
                ? <Chip label="Abgelehnt" color="error"/>
                : <Chip label="Aktiv" color="primary"/>}
            </Box>
        </Stack>
    </FlexCard>
);

const useSnackbar = () => {
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const showSnack = useCallback((message: string) => {
        setSnackbarMessage(message);
        setShowSnackbar(true);
    }, []);

    return {
        showSnackbar,
        snackbarMessage,
        showSnack,
    } as const;
};

const AnimalReview: FunctionComponent = () => {

    const {t} = useTranslation();

    const {showSnackbar, snackbarMessage, showSnack} = useSnackbar();

    const [comment, setComment] = useState('');

    const {id: reviewRequestId} = useParams<AnimalReviewPathParams>();

    const {reviewRequest, similarAnimals, animal, registrations} = useReviewRequest(reviewRequestId);
    const isRejected = !!reviewRequest?.rejectionTime;

    if (!reviewRequest) {
        return null;
    }

    const handleAccept = async () => {
        showSnack(ACCEPTED_MESSAGE);
        await RegistrationReviewService.acceptReview(reviewRequestId);
    }

    const handleReject = async () => {
        showSnack(REJECTED_MESSAGE)
        await RegistrationReviewService.rejectReview(reviewRequestId, comment);
    }

    const transformAnimal = (animal: JsonAnimal | undefined): Map<string, string> => {
        const displayPairs: Map<string, string> = new Map();
        if (!animal) return displayPairs;
        displayPairs.set("Geburtsdatum", animal.dateOfBirth.toLocaleString());
        displayPairs.set("Rasse", t('breed.' + animal.breed));
        displayPairs.set("Farbe", t('color.' + animal.fiberColor));
        //displayPairs.set("Züchter", baseData.breederName); //TODO implement as soon as we have ownership endpoints on the backend
        //displayPairs.set("Besitzer", baseData.ownerName); //TODO implement as soon as we have ownership endpoints on the backend
        return displayPairs;
    }

    return (
        <FlexStack>
            <StatusCard rejected={isRejected} breeder={reviewRequest.breeder.name}/>
            <TabularTextCard title={AnimalService.getAnimalFullName(animal)} displayPairs={transformAnimal(animal)}/>
            <ExistingRegistrationsCard existingRegistrations={registrations}/>
            <ReviewSimilaritiesCard reviewId={reviewRequestId} reviewedAnimal={animal} similarAnimals={similarAnimals}/>
            <PedigreeCard animal={animal}/>
            {isRejected
                ? (
                    <FlexCard title="Kommentare">
                        {reviewRequest.rejectionComment}
                    </FlexCard>
                )
                : <RenderOnRole roles={[REGISTRY_WARDEN, PANON_ADMIN]}>
                    <CommentCard onCommentChange={setComment}/>
                    <Stack direction="row" spacing={1} display="flex" justifyContent="flex-end">
                        <Button onClick={() => handleReject()} variant="outlined">Ablehnen</Button>
                        <Button onClick={() => handleAccept()} variant="contained">Akzeptieren</Button>
                    </Stack>
                </RenderOnRole>}
            <OnCloseNavigationSnackbar message={snackbarMessage} triggerSnackbar={showSnackbar}
                                       expandedRoute={RouteService.REGISTRY_ANIMAL_REVIEW_LIST}/>
        </FlexStack>
    );
};

export default AnimalReview
