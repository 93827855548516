import {BreederSearchResult} from "../../api/generated/herd-breeder";
import {Avatar, LinearProgress, ListItem, ListItemAvatar, Skeleton, Stack, Tab, Tabs} from "@mui/material";
import {TabPanel} from "../Common";
import List from "@mui/material/List";
import {ErrorBox, InfoBox} from "../Common/alerts";
import React from "react";
import {useQuery} from "@tanstack/react-query";
import {BreederService} from "../../services/breeder-service";
import {useTabs} from "../Common/hooks/use-tabs";
import BreederList from "../Common/breeder-list";
import {useTranslation} from "react-i18next";

const useBreeders = (searchTerm: string) => {
    const {data, isLoading, isError} = useQuery({
        queryKey: ['breeders', searchTerm],
        queryFn: async () => BreederService.search(searchTerm),
        enabled: !!searchTerm,
    });

    return {
        breeders: data,
        isLoading,
        isError,
    };
};

const TabbedBreeders = ({breeders, onBreederClick}: {
    breeders: BreederSearchResult[],
    onBreederClick: (breeder: BreederSearchResult) => void;
}) => {
    const [selectedTab, setSelectedTab] = useTabs();
    return (
        <>
            <Tabs value={selectedTab} onChange={setSelectedTab}>
                <Tab label={`Alle (${breeders.length})`}/>
            </Tabs>

            <TabPanel value={selectedTab} index={0}>
                <BreederList breeders={breeders} onListItemClick={onBreederClick} />
            </TabPanel>
        </>
    );
};

const LoadingItem = () => (
    <ListItem>
        <ListItemAvatar><Avatar/></ListItemAvatar>
        <Skeleton width="100%"/>
    </ListItem>
);

export const FilteredBreeders = ({searchTerm, onBreederClick, emptyResultRender}: {
    searchTerm: string;
    onBreederClick: (breeder: BreederSearchResult) => void;
    emptyResultRender?: React.ReactNode
}) => {
    const {t} = useTranslation();
    const {breeders = [], isLoading, isError} = useBreeders(searchTerm);

    if (isLoading) {
        return (
            <Stack>
                <LinearProgress/>

                <Tabs value={0}>
                    <Tab label={`Alle (...)`}/>
                </Tabs>

                <TabPanel value={0} index={0}>
                    <List>
                        <LoadingItem/>
                        <LoadingItem/>
                        <LoadingItem/>
                    </List>
                </TabPanel>
            </Stack>
        );
    }

    if (!searchTerm) {
        return <InfoBox>Suchbegriff eingeben, um die Suche zu starten</InfoBox>;
    }

    if (isError) {
        return <ErrorBox>Fehler beim Laden der Züchter</ErrorBox>;
    }

    if (!breeders.length) {
        return emptyResultRender ? emptyResultRender : <InfoBox>{t('breeders.notFound')}</InfoBox>;
    }

    return <TabbedBreeders breeders={breeders} onBreederClick={onBreederClick}/>;
};