import {useHerd} from "../Common/hooks/use-herd";
import React, {useMemo, useState} from "react";
import List from "@mui/material/List";
import RouteService from "../../services/route-service";
import {
    Autocomplete,
    Chip,
    FormControl,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    TextField,
    Typography
} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {SexBadge} from "../Common/sex-badge";
import {AnimalAvatar} from "../Common/animal-avatar";
import {AnimalRecordStatusValues} from "../../api/generated/herd-animal";

const localeCompare = (a: string, b: string) => a.localeCompare(b);

const HerdTagSelect = ({tags, selectedTags, onTagsSelected}: {
    tags: string[];
    selectedTags: string[];
    onTagsSelected: (tags: string[]) => void;
}) => {
    const distinctTags = useMemo(() => Array.from(new Set(tags)).sort(localeCompare), [tags]);
    const countByTag = useMemo(() => {
        const map = new Map<string, number>();
        for (const tag of tags) {
            const count = map.get(tag) ?? 0;
            map.set(tag, count + 1);
        }
        return map;
    }, [tags]);

    const sortedSelectedTags = useMemo(() => [...selectedTags].sort(localeCompare), [selectedTags]);

    return (
        <FormControl fullWidth>
            <Autocomplete
                multiple
                disableCloseOnSelect
                id="filter-tags-autocomplete"
                limitTags={2}
                options={distinctTags}
                value={sortedSelectedTags}
                onChange={(_: unknown, newTags: string[]) => onTagsSelected(newTags)}
                renderInput={(params) => <TextField {...params} label="Tags"/>}
                renderOption={(props, tag, {selected}) => (
                    <li {...props}>
                        <Chip label={`${tag} (${countByTag.get(tag)})`}
                              color={selected ? 'primary' : 'default'}/>
                    </li>
                )}
            />
        </FormControl>
    );
};

export const HerdQuickList = () => {
    const {herd} = useHerd(AnimalRecordStatusValues.HERD);

    const [activeAnimalId, setActiveAnimalId] = useState('');
    const [selectedTags, setSelectedTags] = useState<string[]>([]);

    const tags = useMemo(() => herd.flatMap(animal => animal.tags), [herd]);
    const filteredHerd = useMemo(
        () => herd.filter(animal => selectedTags.every(t => animal.tags.includes(t))),
        [herd, selectedTags]);

    return (
        <List dense>
            <ListItem>
                <HerdTagSelect tags={tags} selectedTags={selectedTags} onTagsSelected={setSelectedTags}/>
            </ListItem>
            {filteredHerd.map(animal => {
                    const href = RouteService.expand(RouteService.ANIMAL_DETAILS, {panonId: animal.panonIdentifier.id});
                    const selected = animal.id === activeAnimalId;
                    return (
                        <ListItem key={animal.id} disablePadding>
                            <ListItemButton component={RouterLink}
                                            to={href}
                                            onClick={() => setActiveAnimalId(animal.id)}
                                            selected={selected}>
                                <ListItemAvatar>
                                    <SexBadge sex={animal.sex}>
                                        <AnimalAvatar animal={animal}/>
                                    </SexBadge>
                                </ListItemAvatar>
                                <ListItemText>
                                    <Typography sx={{fontWeight: selected ? 'bold' : 'normal'}}>
                                        {animal.name}
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    );
                }
            )}
        </List>
    );
};
