// @flow
import {IconButton, List, ListItem, ListItemText, Typography} from '@mui/material';
import React, {FunctionComponent, Suspense} from 'react';
import {BreederService} from "../services/breeder-service";
import {AddLink} from "@mui/icons-material";
import RouteService from "../services/route-service";
import FlexStack from '../components/Common/flex-stack';
import {useNav} from "../components/Common/hooks/use-nav";
import {LoadingFallback} from "../components/Common/loading-fallback";
import {useSuspenseQuery} from "@tanstack/react-query";

const UnlinkedBreedersList: FunctionComponent = () => {
    const nav = useNav();
    const {data: unlinkedBreeders} = useSuspenseQuery({
        queryKey: ['unlinked-breeders'],
        queryFn: () => BreederService.listUnlinked(),
    });

    const handleAddLinkClick = (breederName: string) =>
        nav.push(RouteService.ADMIN_LINK_ACCOUNT_WITH_BREEDER_EDITOR, {breederName});

    return (
        <List>
            {unlinkedBreeders.map((breeder: string) => (
                <ListItem key={breeder}
                          secondaryAction={
                              <IconButton edge="end" aria-label="link" onClick={() => handleAddLinkClick(breeder)}>
                                  <AddLink/>
                              </IconButton>
                          }
                >
                    <ListItemText primary={breeder}/>
                </ListItem>
            ))}
        </List>
    );
};

const AdminAccountsLink: FunctionComponent = () => (
    <FlexStack>
        <Typography variant="h4">Unverlinkte Züchter</Typography>
        <Suspense fallback={<LoadingFallback />}>
            <UnlinkedBreedersList/>
        </Suspense>
    </FlexStack>
);

export default AdminAccountsLink