import {FormControl, SxProps, Theme} from "@mui/material";
import React, {FunctionComponent} from "react";

import {Controller} from "react-hook-form";
import {DatePicker} from "@mui/x-date-pickers";
import {DateTime} from "luxon";

interface ReactHookDatePickerProps {
    name: string,
    label: string,
    control: any,
    defaultValue?: DateTime
    required?: boolean
    disabled?: boolean
    sx?: SxProps<Theme>
    error?: boolean
}

const ReactHookDatePicker: FunctionComponent<ReactHookDatePickerProps> = ({
                                                                              name,
                                                                              label,
                                                                              control,
                                                                              defaultValue,
                                                                              required = false,
                                                                              disabled = false,
                                                                              sx,
                                                                              error = false
                                                                          }) => {
    return (
        <Controller defaultValue={defaultValue} control={control} name={name} render={({field}) => (
            <FormControl sx={sx} required={required} error={error}>
                <DatePicker
                    // disableMaskedInput
                    disabled={disabled}
                    label={label}
                    {...field}
                />
            </FormControl>
        )}/>
    );
};
export default ReactHookDatePicker;