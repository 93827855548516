import {PregnancyListJson} from "../../../../api/generated/medical-rest";
import {ParentType, ParentTypeValues} from "../../../../api/generated/herds-pedigree";
import {PregnancyService} from "../../../../services/pregnancy-service";
import {JsonAnimal} from "../../../../api/generated/rest-dto";
import {useQuery} from "@tanstack/react-query";

export const usePartners = (pregnancies: PregnancyListJson[], baseParentKind: ParentType) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [pregnancies.map(p => p.id), baseParentKind],
        queryFn: async () => {
            const partnerPromises = [];
            for (const p of pregnancies) {
                partnerPromises.push(baseParentKind === ParentTypeValues.SIRE ? PregnancyService.findMother(p) : PregnancyService.findFather(p));
            }

            const partners = (await Promise.all(partnerPromises)).filter((p): p is JsonAnimal => !!p);
            return new Map(partners.map(partner => [partner.panonIdentifier.id, partner]));
        },
    });

    return {
        partners: data,
        isLoading,
        isError,
    }
};
