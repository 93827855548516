// @flow
import React, {FunctionComponent, useState} from 'react';
import RegistryList from "./registry-list";
import {RegistryService} from "../../services/registry-service";
import {Stack, Typography} from "@mui/material";
import ConfirmDialog from "../Common/confirm-dialog";
import OnCloseNavigationSnackbar from "../Common/on-close-navigation-snackbar";
import RouteService from "../../services/route-service";
import {useParams} from "react-router-dom";
import {RegistrationService} from "../../services/registration-service";
import {AnimalService} from "../../services/animal-service";
import {JsonAnimal} from "../../api/generated/rest-dto";
import {RegistryDto} from "../../api/generated/registry-service";
import {useQuery} from "@tanstack/react-query";

interface SelectRegistryForAnimalRequestParams {
    panonId: string,
}

const SelectRegistryForAnimalRequest: FunctionComponent = () => {

    const [open, setOpen] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [selectedRegistry, setSelectedRegistry] = useState<RegistryDto | null>(null);

    const {panonId} = useParams<SelectRegistryForAnimalRequestParams>();

    const { data: registries = [] } = useQuery({
        queryKey: ['registries', panonId],
        queryFn: async () => {
            const [registries, registrations] = await Promise.all([
                RegistryService.findAllWhereBreederIsMember(),
                RegistrationService.findRegistrationsByPanonId(panonId)
            ]);

            return registries.filter(r => registrations.find(registration => registration.registryId === r.id) === undefined);
        },
    });

    const handleConfirm = async () => {
        if (selectedRegistry) {
            const animal:JsonAnimal = await AnimalService.loadJsonAnimalByPanonId(panonId);
            await RegistrationService.submitRequest(selectedRegistry, animal)
        }
        setOpen(false);
        setOpenSnackbar(true);
    }

    return (
        <Stack>
            <Typography variant="h3" align="center">Register auswählen</Typography>
            <ConfirmDialog title={`Möchtest du eine Aufnahme in das Register ${selectedRegistry ? selectedRegistry.name : ""} beantragen?`} okButtonText="Beantragen"
                           onClose={() => setOpen(false)} onOk={handleConfirm} open={open}/>
            <RegistryList registries={registries} onListItemClick={(registry) => {
                setSelectedRegistry(registry);
                setOpen(true)
            }}/>
            <OnCloseNavigationSnackbar message="Registrierung beantragt" triggerSnackbar={openSnackbar} expandedRoute={RouteService.expand(RouteService.ANIMAL_DETAILS, {panonId: panonId})}/>
        </Stack>
    );
};

export default SelectRegistryForAnimalRequest
