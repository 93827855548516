// @flow
import React, {FunctionComponent} from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {DateTime} from "luxon";
import {DatePicker} from "@mui/x-date-pickers";
import useFormFields from "../../Common/hooks/use-form-fields";
import {Stack} from "@mui/material";

type Props = {
    open: boolean;
    startDate?: DateTime;
    onSave: (confirmedDate: DateTime, calculatedEndDate: DateTime) => void;
    onCancel: () => void;
    title: string
};

const ConfirmPregnancyDialog: FunctionComponent<Props> = ({
                                                              open,
                                                              startDate = DateTime.now(),
                                                              onSave,
                                                              onCancel,
                                                              title
                                                          }) => {
    const defaultFormValues = {startDate: startDate};
    const {formFields, createChangeHandler} = useFormFields({...defaultFormValues});

    const startDateChangeHandler = createChangeHandler("startDate");

    const calculatedEndDate = formFields.startDate.plus({days: 350});

    return (
        <Dialog open={open} onClose={onCancel} aria-labelledby='form-dialog-title'>
            <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
            <DialogContent>

                <Stack spacing={2}>
                    <DatePicker
                        format="MM/dd/yyyy"
                        label="Trächtigkeitsbeginn"
                        value={formFields.startDate}
                        onChange={startDateChangeHandler}
                    />

                    <DatePicker
                        disabled
                        format="MM/dd/yyyy"
                        label="Errechneter Geburtstermin"
                        value={calculatedEndDate}
                        onChange={() => null}
                    />
                </Stack>

            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="secondary">Cancel</Button>
                <Button onClick={() => onSave(formFields.startDate, calculatedEndDate)}
                        color="primary"
                        variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmPregnancyDialog
