import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Link as RouterLink, LinkProps as RouterLinkProps} from 'react-router-dom';
import {DistributiveOmit} from '@mui/types';
import {ListItemButton, SxProps} from '@mui/material';

type ListItemLinkProps = {
    icon?: React.ReactElement;
    primary: string;
    to: string;
    onClickHandler?: () => void;
    sx?: SxProps;
};

const ListItemLink = (props: ListItemLinkProps) => {
    const {icon, primary, to} = props;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((itemProps, ref) => (
                <RouterLink to={to} ref={ref} {...itemProps} />
            )),
        [to],
    );

    return (
        <ListItem sx={props.sx} disablePadding>
            <ListItemButton onClick={props.onClickHandler} component={renderLink}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary}/>
            </ListItemButton>
        </ListItem>
    );
};

export default ListItemLink;