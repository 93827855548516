import React from "react";
import FlexStack from "../Common/flex-stack";
import {RegistryService} from "../../services/registry-service";
import {List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {useNav} from "../Common/hooks/use-nav";
import RouteService from "../../services/route-service";
import {useSuspenseQuery} from "@tanstack/react-query";

const useRegistriesOfMember = () => useSuspenseQuery({
    queryKey: ['registries', 'of-member'],
    queryFn: async () => RegistryService.findAllWhereBreederIsMember(),
});

export const RegistryListPage = () => {
    const {data: registries} = useRegistriesOfMember();
    const nav = useNav();

    const onSearchResultClick
        = (registryId: string) => nav.push(RouteService.REGISTRY_ANIMAL_LIST, {registryId});

    return (
        <FlexStack>
            <List>
                {registries.map(registry =>
                    <ListItem key={registry.id}>
                        <ListItemButton onClick={() => onSearchResultClick(registry.id)}>
                            <ListItemText primary={registry.name}/>
                        </ListItemButton>
                    </ListItem>
                )}
            </List>
        </FlexStack>
    );
};