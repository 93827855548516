// @flow
import React, {FunctionComponent} from 'react';
import {Button, MenuItem, SxProps, TextField} from "@mui/material";
import {
    AlpacaBreedValues,
    AlpacaFiberColorValues,
    AnimalIdentifierTypeValues,
    SexValues
} from "../../api/generated/herd-animal";
import {DateTime} from "luxon";
import {v4 as uuidv4} from "uuid";
import SaveIcon from "@mui/icons-material/Save";
import {useForm} from 'react-hook-form';
import FlexStack from '../Common/flex-stack';
import ReactHookFormSelect from "../Common/react-hook-form-select";
import ReactHookDatePicker from "../Common/react-hook-date-picker";
import {AnimalBaseData} from "../../api/dtos/animal-base-data";
import {PregnancyListJson} from "../../api/generated/medical-rest";
import {useTranslation} from "react-i18next";

interface AnimalBaseDataFormProps {
    onSubmitClicked: (animalToSave: AnimalBaseData) => void
    onCancelClicked?: () => void
    initialData?: AnimalBaseData
    allowIdUpdate?: boolean
    pregnancy?: PregnancyListJson
}

const REQUIRED_FIELD_MESSAGE: string = "Pflichtfeld"; //TODO get i18n to work

const styles: Record<string, SxProps> = {
    root: {
        '& .MuiTextField-root': {
            margin: 1
        },
        display: "flex",
        flexDirection: "column",
        maxWidth: 500
    },
    button: {
        margin: 1,
    },
    formControl: {
        margin: 1,
        minWidth: 120,
    }
};

const AnimalBaseDataForm: FunctionComponent<AnimalBaseDataFormProps> = ({
                                                                            onSubmitClicked,
                                                                            initialData,
                                                                            onCancelClicked,
                                                                            pregnancy,
                                                                            allowIdUpdate = true
                                                                        }) => {
        const INITIAL_CREATE_ANIMAL_DTO: AnimalBaseData = initialData ?? {
            breed: AlpacaBreedValues.HUACAYA,
            dateOfBirth: pregnancy?.actualEndDate ?? DateTime.now(),
            fiberColor: AlpacaFiberColorValues.WHITE,
            herdCode: "",
            name: "",
            sex: SexValues.MALE,
            chipNumber: "",
            panonIdentifier: {
                animalIdentifiers: [
                    {
                        position: "left",
                        type: AnimalIdentifierTypeValues.CHIP,
                        value: ""
                    }
                ],
                id: uuidv4()
            }
        };

        const {
            formState,
            register,
            handleSubmit,
            control
        } = useForm<AnimalBaseData>({defaultValues: INITIAL_CREATE_ANIMAL_DTO});
        const {t} = useTranslation();
        const {errors} = formState;

        return (
            <div>
                <form onSubmit={handleSubmit(onSubmitClicked)} autoComplete="off" noValidate>
                    <FlexStack>
                        <TextField id="animal-registration-name" required
                                   label="Tiername"
                                   {...register("name", {required: {value: true, message: REQUIRED_FIELD_MESSAGE}})}
                                   error={!!errors.name}
                                   helperText={errors.name ? errors.name.message : ''}
                        />
                        <TextField id="animal-registration-herd-code" required
                                   {...register("herdCode", {required: {value: true, message: REQUIRED_FIELD_MESSAGE}})}
                                   label="Herdkürzel"
                                   error={!!errors.herdCode}
                                   helperText={errors.herdCode?.message ?? ''}
                        />
                        {allowIdUpdate && <TextField id="animal-registration-identification"
                                                     label="Chip/Ohrmarke Nr."
                                                     {...register("chipNumber")}
                        />
                        }

                        <ReactHookFormSelect name="fiberColor" label="Farbe" control={control} required={true}>
                            {Object.keys(AlpacaFiberColorValues)
                                .map(c => <MenuItem key={c} value={c}>{t('color.' + c)}</MenuItem>)}
                        </ReactHookFormSelect>

                        <ReactHookFormSelect name="sex" label="Geschlecht" control={control} required={true}>
                            {Object.keys(SexValues)
                                .map(s => <MenuItem key={s} value={s}>{t('sex.' + s)}</MenuItem>)}
                        </ReactHookFormSelect>
                        <ReactHookFormSelect name="breed" label="Rasse" control={control} required={true}>
                            {Object.keys(AlpacaBreedValues)
                                .map(b => <MenuItem key={b} value={b}>{t('breed.' + b)}</MenuItem>)}
                        </ReactHookFormSelect>
                        <ReactHookDatePicker name="dateOfBirth" label="Geburtstag" control={control} required={true}
                                             error={!!errors.dateOfBirth}/>
                        <Button
                            className="btn-create-animal"
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                            sx={styles.button}
                            startIcon={<SaveIcon/>}
                        >
                            Speichern
                        </Button>
                        {onCancelClicked && <Button
                            className="btn-create-animal"
                            variant="outlined"
                            color="primary"
                            size="large"
                            sx={styles.button}
                            onClick={onCancelClicked}
                        >
                            Abbrechen
                        </Button>
                        }
                    </FlexStack>
                </form>
            </div>
        );
    }
;

export default AnimalBaseDataForm