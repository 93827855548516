// @flow
import React, {FunctionComponent} from 'react';
import {BreederService} from "../services/breeder-service";
import {FarmData} from "../api/dtos/breeder-update";
import BreederDetailsForm from "../components/Breeder/breeder-details-form";
import {useMutation, useQuery} from '@tanstack/react-query';


const Farm: FunctionComponent = () => {


    const breederUpdateMutation = useMutation({
        mutationKey: ['breederUpdate'],
        mutationFn: (farmData: FarmData) => BreederService.updateBreeder(farmData)
    })

    const {data, dataUpdatedAt} = useQuery({
        queryKey: ["ownDetails"],
        queryFn: async () => {
            const details = await BreederService.ownDetails();
            return {
                ...details,
                owners: details.owners.map(o => ({name: o}))
            };
        }
    })

    //The key forces a reset of the form in case the updated data timestamp changed
    return (!data ? null : <BreederDetailsForm key={`own-details-form-${dataUpdatedAt}`} onSubmit={breederUpdateMutation.mutate} initialData={data} />)
};

export default Farm