import {RegistrationService} from "../../../services/registration-service";
import {useQuery} from "@tanstack/react-query";

export const useRegistrations = (panonId: string | undefined) => {
    const {data, isLoading, isError, refetch} = useQuery({
        queryKey: ['registrations', panonId],
        queryFn: () => RegistrationService.findRegistrationsByPanonId(panonId!),
        enabled: !!panonId,
    });

    return {
        registrations: data,
        isLoading,
        isError,
        refetch
    };
};
