import React, {FC, useEffect, useState} from 'react';
import {RouteComponentProps, useLocation, withRouter} from 'react-router-dom';
import {AnimalService} from "../services/animal-service";
import {PedigreeService} from "../services/pedigree-service";
import AnimalCard from '../components/AnimalCard';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {JsonAnimal} from "../api/generated/rest-dto";
import FlexStack from "../components/Common/flex-stack";
import {SexValues} from "../api/generated/herd-animal";
import {useGlobalSnackbarStore} from "../stores/global-snackbar-store";
import ParentPicker from '../components/Pedigree/parent-picker';
import {Result} from "../api/generated/lib-rest";
import RouteService from "../services/route-service";
import {useAnimal} from "../components/Common/hooks/use-animal";
import {useQuery} from "@tanstack/react-query";

const usePedigreeRecord = (panonId: string | undefined) => {
    const {data, isLoading, isError} = useQuery({
        queryKey: ['pedigree-record', panonId],
        queryFn: async () => PedigreeService.loadPedigreeRecord(panonId!),
        enabled: !!panonId,
    });

    return {
        pedigree: data ?? {damId: '', panonId: panonId!, sireId: ''},
        isLoading,
        isError,
    };
};

interface AnimalAncestryCardProps extends RouteComponentProps {
    match: any
}

interface AncestryChangeLocationState {
    expandedRedirectUrl?: string
}

const AnimalAncestry: FC<AnimalAncestryCardProps> = ({match}) => {
    const panonId: string = match.params.panonId;

    const {success, error} = useGlobalSnackbarStore(state => state);

    const {state: locationState} = useLocation<AncestryChangeLocationState>();
    const {animal: offspring} = useAnimal(panonId);

    const {pedigree} = usePedigreeRecord(panonId);

    const [dam, setDam] = useState<JsonAnimal>();
    const [sire, setSire] = useState<JsonAnimal>();

    useEffect(() => {
        const fetchData = async () => {
            if (pedigree.sireId) {
                setSire(await AnimalService.loadJsonAnimalByPanonId(pedigree.sireId));
            }
            if (pedigree.damId) {
                setDam(await AnimalService.loadJsonAnimalByPanonId(pedigree.damId));
            }
        };

        void fetchData();
    }, [pedigree]);

    if (!offspring?.id) return null;

    const handleSaveClick = async () => {
        try {
            const result: Result = await PedigreeService.upsertPedigreeRecord({
                damId: dam?.panonIdentifier?.id ?? '',
                sireId: sire?.panonIdentifier?.id ?? '',
                panonId
            });
            if (result.info === "success") {
                const redirectUrl = locationState?.expandedRedirectUrl
                    ? locationState.expandedRedirectUrl
                    : RouteService.expand(RouteService.ANIMAL_DETAILS, {panonId});
                success("Stammbaum gespeichert!", redirectUrl);
            } else {
                error("Aktualisierung fehlgeschlagen!");
            }
        } catch (e) {
            error("Aktualisierung fehlgeschlagen!");
        }
    }

    const handleParentSelected = (parent: JsonAnimal) => {
        switch (parent.sex) {
            case SexValues.FEMALE:
                setDam(parent);
                break;
            case SexValues.MALE:
                setSire(parent);
                break;
            default:
                error("Das ausgewählte Elterntier war weder männlich noch weiblich!");
        }
    }

    return (
        <FlexStack spacing={3}>
            <Typography variant="h3" align="center">Stammbaum bearbeiten</Typography>
            <AnimalCard data={offspring}/>
            <ParentPicker sire={sire} dam={dam} onParentSelected={handleParentSelected}/>
            <Button variant="contained" onClick={handleSaveClick}><Typography>Speichern</Typography></Button>
        </FlexStack>
    );
}

export default withRouter(AnimalAncestry);
