import {AlpacaBreed, AlpacaFiberColor, PanonIdentifier, Sex} from "../generated/herd-animal";
import {DateTime} from "luxon";
import {CreateAnimalDto, JsonAnimal} from "../generated/rest-dto";
import {RegistrationJson} from "../generated/registry-rest";

export type AnimalBaseData = {
    panonIdentifier: PanonIdentifier,
    name: string,
    fiberColor: AlpacaFiberColor,
    herdCode: string,
    sex: Sex,
    breed: AlpacaBreed
    dateOfBirth: DateTime,
    chipNumber: string,
}

export const transformFromJsonAnimal = (jsonAnimal: JsonAnimal): AnimalBaseData => {
    return {
        ...jsonAnimal,
        breed: jsonAnimal.breed as AlpacaBreed,
        chipNumber: jsonAnimal.panonIdentifier.animalIdentifiers[0]?.value
    }
}

export const transformFromRegistration = (registration: RegistrationJson): AnimalBaseData => {
    return {
        ...registration.phenotype,
        panonIdentifier: {id: registration.panonIdentifier, animalIdentifiers: []},
        name: registration.name,
        herdCode: registration.herdCode,
        fiberColor: registration.phenotype.primaryColor,
        chipNumber: ""
    }
}

export const transformToCreateAnimalDto = (animal: AnimalBaseData, pregnancyId?:string): CreateAnimalDto => {
    const animalIdentifiers = animal.panonIdentifier?.animalIdentifiers?.length
        ? [
            {
                ...animal.panonIdentifier.animalIdentifiers[0],
                value: animal.chipNumber,
            },
        ].filter(id => !!id.value)
        : [];

    return {
        breed: animal.breed,
        dateOfBirth: animal.dateOfBirth,
        fiberColor: animal.fiberColor,
        herdCode: animal.herdCode,
        name: animal.name,
        animalIdentifiers,
        pregnancyId: pregnancyId?.trim() ? pregnancyId : null,
        sex: animal.sex
    }
}