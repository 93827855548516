// @flow
import React, {FC, Fragment, Suspense, useCallback, useMemo, useState} from 'react';
import {
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from "@mui/material";
import {RegistryService} from "../../services/registry-service";
import {RegistrationJson, RegistrationRequestController$PendingRequestJson} from "../../api/generated/registry-rest";
import {ErrorBox} from '../Common/alerts';
import {useSuspenseQuery} from "@tanstack/react-query";
import {RegistryDto} from "../../api/generated/registry-service";
import {useNav} from '../Common/hooks/use-nav';
import RouteService from "../../services/route-service";
import {JsonAnimal} from "../../api/generated/rest-dto";
import {Feedback, HourglassBottom, SyncAlt, ThumbDown} from "@mui/icons-material";
import {RegistrationService} from "../../services/registration-service";
import ConfirmDialog from '../Common/confirm-dialog';
import {LoadingFallback} from "../Common/loading-fallback";
import {withErrorBoundary} from "react-error-boundary";

type AnimalRegistrationsListProps = {
    herdAnimal?: JsonAnimal,
    animalRegistrations: RegistrationJson[]
    pendingRegistrations: RegistrationRequestController$PendingRequestJson[],
    withDivider?: boolean
};

type Registration = Omit<RegistrationJson, 'registryId'> & {
    registry: RegistryDto;
};

const useRegistrations = (registrations: RegistrationJson[]) => {
    const registryIds = Array.from(new Set(registrations.map(r => r.registryId)));
    const { data } = useSuspenseQuery({
        queryKey: ['registries', {ids: registryIds}],
        queryFn: () => {
            //TODO batch request
            const registryPromises = registryIds.map(id => RegistryService.loadById(id));
            return Promise.all(registryPromises);
        },
        select: registries => {
            const registriesById = new Map(registries.map(reg => [reg.id, reg]));
            return registrations.map(reg => {
                const registry = registriesById.get(reg.registryId);
                if (!registry) {
                    return null;
                }
                const registration: Registration = {
                    ...reg,
                    registry,
                };
                return registration;
            })
                .filter((reg): reg is Registration => !!reg);
        },
    });

    return {registrations: data};
};

const isRegistered = (animal: JsonAnimal, registration: Registration) => {
    // TODO anything else to check?
    const phenotype = registration.phenotype;
    return animal.name === registration.name
        && animal.herdCode === registration.herdCode
        && animal.sex === phenotype.sex
        && animal.breed === phenotype.breed
        && animal.fiberColor === phenotype.primaryColor
        && +animal.dateOfBirth === +phenotype.dateOfBirth;
};

const AnimalRegistrationListItem: FC<{ animal?: JsonAnimal; registration: Registration; hasPending?: boolean }> = ({animal, registration, hasPending}) => {
    const registry = registration.registry;

    const nav = useNav();
    const [dialogOpen, setDialogOpen] = useState(false);
    const showDialog = useCallback(() => setDialogOpen(true), [setDialogOpen]);
    const hideDialog = useCallback(() => setDialogOpen(false), [setDialogOpen]);

    const hasChanged = animal && !isRegistered(animal, registration);

    const openRegistration = () => nav.push(
        RouteService.REGISTERED_ANIMAL_DETAILS,
        {registryId: registry.id, panonId: registration.panonIdentifier});

    if (!animal || !hasChanged) {
        return (
            <ListItem>
                <ListItemButton onClick={openRegistration}>
                    <ListItemText inset>{registry.name}</ListItemText>
                </ListItemButton>
            </ListItem>
        );
    }

    const submitRegistrationUpdateRequest = async () => {
        animal && await RegistrationService.submitRequestWithIds(registry.id, animal.panonIdentifier.id);
        hideDialog();
    }

    return (
        <ListItem secondaryAction={!hasPending && (
            <IconButton onClick={showDialog}>
                <SyncAlt/>
            </IconButton>
        )}>
            <ListItemButton onClick={openRegistration}>
                <ListItemIcon>{hasPending ? <HourglassBottom/> : <Feedback color="warning"/>}</ListItemIcon>
                <ListItemText>{registry.name}</ListItemText>
            </ListItemButton>
            <ConfirmDialog
                title={`Aktualisierung der Daten in Register ${registry?.name ?? ''} beantragen?`}
                okButtonText="Aktualisierung beantragen"
                onClose={hideDialog}
                onOk={submitRegistrationUpdateRequest}
                open={dialogOpen}/>
        </ListItem>
    );
};

const PendingRegistrationListItem: FC<{ animal?: JsonAnimal; registration: RegistrationRequestController$PendingRequestJson; }> = ({animal, registration}) => {
    const registry = registration.registry;
    const isRejected = !!registration.rejectionTime;

    const nav = useNav();

    const openRegistration = () => nav.push(RouteService.REGISTRY_ANIMAL_REVIEW, {id: registration.id});

    return (
        <ListItem>
            <ListItemButton onClick={openRegistration}>
                <ListItemIcon>{isRejected && <ThumbDown color="error"/>}</ListItemIcon>
                <ListItemText>{registry.name}</ListItemText>
            </ListItemButton>
        </ListItem>
    );
};

const AnimalRegistrationsListWrapper: FC<AnimalRegistrationsListProps> = ({
                                                                              herdAnimal,
                                                                              animalRegistrations,
                                                                              pendingRegistrations,
                                                                              withDivider = false
                                                                          }) => {
    const {registrations} = useRegistrations(animalRegistrations);

    const pendingRegistryIds = useMemo(
        () => new Set(pendingRegistrations.map(r => r.registry.id)),
        [pendingRegistrations]);

    return (
        <List>
            {registrations.map(registration => {
                const hasPending = pendingRegistryIds.has(registration.registry.id);
                return (
                    <Fragment key={registration.id}>
                        <AnimalRegistrationListItem animal={herdAnimal} registration={registration} hasPending={hasPending}/>
                        {withDivider && <Divider variant="middle" component="li"/>}
                    </Fragment>
                );
            })}
            {!!pendingRegistrations.length && (
                <>
                    <Typography variant="h6">Anmeldungen</Typography>
                    {pendingRegistrations.map(reg => <PendingRegistrationListItem key={reg.id} registration={reg}/>)}
                </>
            )}
        </List>
    );
};

const AnimalRegistrationsList: FC<AnimalRegistrationsListProps> = ({
                                                                       herdAnimal,
                                                                       animalRegistrations,
                                                                       pendingRegistrations,
                                                                       withDivider = false
                                                                   }) => {
    return (
        <Suspense fallback={<LoadingFallback/>}>
            <AnimalRegistrationsListWrapper
                animalRegistrations={animalRegistrations}
                pendingRegistrations={pendingRegistrations}
                herdAnimal={herdAnimal}
                withDivider={withDivider}/>
        </Suspense>
    );
};

export default withErrorBoundary(
    AnimalRegistrationsList,
    {
        fallback: <ErrorBox>Fehler beim Anzeigen der Registrierungen!</ErrorBox>
    });
