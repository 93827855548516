// @flow
import React, {Fragment, FunctionComponent, PropsWithChildren, ReactNode} from 'react';
import {Chip, ListItem, ListItemAvatar, ListItemButton, ListItemText, SvgIcon} from "@mui/material";
import FormattedDate from "../Common/formatted-date";
import ChipsList from "../chips-list";
import {JsonAnimal} from "../../api/generated/rest-dto";
import {PregnancyListJson} from "../../api/generated/medical-rest";
import {getPregnancyState, PregnancyState} from "../AnimalDashboard/Pregnancy/pregnancy-utils";
import {ReactComponent as MatingDateSvg} from "../../icons/mating_date.svg"
import {Flare} from '@mui/icons-material';
import {AnimalAvatar} from "../Common/animal-avatar";
import {SexBadge} from "../Common/sex-badge";

type AnimalListItemProps = {
    showTags?: boolean,
    onTagClick?: (tag: string) => void,
    animal: JsonAnimal & { pregnancy?: PregnancyListJson },
    onItemClick?: (animal: JsonAnimal) => void
    secondaryAction?: ReactNode
};

type ConditionalListItemButtonProps = PropsWithChildren<{
    onClick?: () => void
}>;

const ConditionalListItemButton: FunctionComponent<ConditionalListItemButtonProps> = ({onClick, children}) => {
    return onClick ? <ListItemButton onClick={onClick}>{children}</ListItemButton> : <>{children}</>;
}

type Optional<T> = T | null | undefined

const getPregnancyChipLabel = (pregnancyState: Optional<PregnancyState>) => {
    switch (pregnancyState) {
        case PregnancyState.MATED: return 'gedeckt';
        case PregnancyState.PREGNANT: return 'trächtig';
        default: return '';
    }
};

const getPregnancyIcon = (pregnancyState: Optional<PregnancyState>) => {
    switch (pregnancyState) {
        case PregnancyState.MATED: return <SvgIcon><MatingDateSvg/></SvgIcon>;
        case PregnancyState.PREGNANT: return <Flare/>;
        default: return <></>;
    }
};

const AnimalListItem: FunctionComponent<AnimalListItemProps> = ({
                                                                    animal,
                                                                    onItemClick,
                                                                    onTagClick,
                                                                    showTags = false,
                                                                    secondaryAction
                                                                }) => {

    const handleListItemClick = onItemClick ? () => onItemClick(animal) : undefined;

    const pregnancyState = animal.pregnancy && getPregnancyState(animal.pregnancy);
    const pregnancyChipLabel = getPregnancyChipLabel(pregnancyState);
    const title = pregnancyChipLabel
        ? <>{animal.name} <Chip label={pregnancyChipLabel} size="small" icon={getPregnancyIcon(pregnancyState)}/></>
        : animal.name;

    return (
        <ListItem secondaryAction={secondaryAction} alignItems="flex-start" disablePadding>
            <ConditionalListItemButton onClick={handleListItemClick}>
                <ListItemAvatar>
                    <SexBadge sex={animal.sex}>
                        <AnimalAvatar animal={animal}/>
                    </SexBadge>
                </ListItemAvatar>
                <ListItemText primary={title} secondary={
                    <FormattedDate sx={{textAlign: "right"}} date={animal.dateOfBirth}/>
                }/>
                {showTags && <ChipsList listItems={animal.tags} onItemClick={onTagClick}/>}
            </ConditionalListItemButton>
        </ListItem>
    );
};

export default AnimalListItem