import FlexStack from "../Common/flex-stack";
import {RegistryService} from "../../services/registry-service";
import {useParams} from "react-router-dom";
import React, {ReactNode, Suspense, useState} from "react";
import {Pagination, Stack, TextField, Typography} from "@mui/material";
import {useNav} from "../Common/hooks/use-nav";
import RouteService from "../../services/route-service";
import AnimalSearchResultCard from "../Common/animal-search-result-card";
import AnimalSearchResult from "../../api/dtos/animal-search-result";
import {RegistryDto} from "../../api/generated/registry-service";
import {LoadingFallback} from "../Common/loading-fallback";
import {InfoBox} from "../Common/alerts";
import {useSuspenseQuery} from "@tanstack/react-query";

const useRegistry = (registryId: string) => {
    return useSuspenseQuery({
        queryKey: ['registry', {registryId}],
        queryFn: async () => registryId ? RegistryService.loadById(registryId) : null,
    });
};

const useSearchRegistrations = (registryId: string, searchTerm: string) => {
    return useSuspenseQuery({
        queryKey: ['registrations', {registryId, search: searchTerm}],
        queryFn: async () => registryId ? RegistryService.searchRegistrations(registryId, searchTerm) : [],
    });
};

type RegistryAnimalListParams = {
    registryId: string
};

const Paginated = <T, >({items, pageSize = 10, children}: {
    items: T[],
    pageSize?: number,
    children: (pageItems: T[]) => ReactNode,
}) => {
    const [page, setPage] = useState(1);
    const pageCount = Math.ceil(items.length / pageSize);
    const pageItems = items.slice((page - 1) * pageSize, page * pageSize);

    return (
        <>
            {children(pageItems)}
            <Pagination count={pageCount} page={page} onChange={(ev, p) => setPage(p)}/>
        </>
    );
};

const RegistryAnimalSearchResult = ({registry, searchTerm}:{registry:RegistryDto, searchTerm: string}) => {
    const nav = useNav();
    const {data: registrations} = useSearchRegistrations(registry.id, searchTerm);

    if (!registrations) {
        return null;
    }

    const openAnimalDetails
        = (panonId: string) => nav.push(RouteService.REGISTERED_ANIMAL_DETAILS, {registryId: registry.id, panonId});

    if (registrations.length === 0) {
        return <InfoBox>Keine Registrierungen gefunden.</InfoBox>;
    }

    return (
        <>
            <Typography>{registrations.length} von {registrations.length} Tieren im Register werden angezeigt</Typography>
            <Paginated key={searchTerm} items={registrations} pageSize={5}>
                {page => (
                    <Stack spacing={1}>
                        {page.map(r => AnimalSearchResult.FromRegistration(r, registry)).map(r =>
                            <AnimalSearchResultCard
                                key={r.panonId.id}
                                animalSearchResult={r}
                                handleClick={() => openAnimalDetails(r.panonId.id)}
                                clickable={true}/>
                        )}
                    </Stack>
                )}
            </Paginated>
        </>
    );
};

export const RegistryAnimalListPage = () => {
    const {registryId} = useParams<RegistryAnimalListParams>();
    const [searchTerm, setSearchTerm] = useState('');
    const {data: registry} = useRegistry(registryId);

    if (!registry) {
        return null;
    }

    return (
        <FlexStack key={registryId}>
            <Typography variant="h3">Register: {registry.name}</Typography>
            <TextField label="Suche" variant="outlined" value={searchTerm} onChange={ev => setSearchTerm(ev.target.value)}/>
            <Suspense fallback={<LoadingFallback message="Registerdaten werden geladen ..."/>}>
                {searchTerm.length < 3
                    ? <InfoBox>Geben Sie mindestens 3 Zeichen ein, um die Suche zu starten.</InfoBox>
                    : <RegistryAnimalSearchResult searchTerm={searchTerm} registry={registry}/>}
            </Suspense>
        </FlexStack>
    );
}
