// @flow
import React from 'react';
import {useParams} from "react-router-dom";
import FlexStack from "../components/Common/flex-stack";
import BaseData from "../components/AnimalDashboard/base-data-widget";
import PedigreeWidget from "../components/AnimalDashboard/Pedigree/pedigree-widget";
import {RegistryService} from "../services/registry-service";
import {RegistrationService} from "../services/registration-service";
import {RegistrationsController$RegistrationEditJson} from "../api/generated/registry-rest";
import RouteService from "../services/route-service";
import {AnimalBaseData, transformFromRegistration} from "../api/dtos/animal-base-data";
import {useGlobalSnackbarStore} from "../stores/global-snackbar-store";
import {OwnerWidget} from "../components/AnimalDashboard/owner-widget";
import {Widget} from "../components/Common";
import {useNav} from '../components/Common/hooks/use-nav';
import {LinearProgress, List, ListItem, ListItemButton, Typography} from "@mui/material";
import {useSuspenseAnimal} from "../components/Common/hooks/use-animal";
import {useSuspenseQuery} from "@tanstack/react-query";

const useRegistrations = (panonId: string) => useSuspenseQuery({
    queryKey: ['registrations', panonId],
    queryFn: async () => panonId ? RegistrationService.findRegistrationsByPanonId(panonId) : [],
});

const useRegistries = (registryIds: string[]) => useSuspenseQuery({
    queryKey: ['registries', registryIds],
    queryFn: async () => registryIds.length > 0 ? RegistryService.loadByIds(registryIds) : [],
});

const useRegistration = (registryId: string, panonId: string) => useSuspenseQuery({
    queryKey: ['registration', {registryId, panonId}],
    queryFn: async () => registryId && panonId ? RegistrationService.loadRegistration(registryId, panonId) : null,
});

const useWardenRegistries = () => useSuspenseQuery({
    queryKey: ['registries', 'of-warden'],
    queryFn: async () => RegistryService.findAllWhereBreederIsWarden(),
    select: registries => registries.map(r => r.id),
});

const RegistrationsWidget = ({panonId, activeRegistryId, onClick}: {
    panonId: string,
    activeRegistryId: string,
    onClick: (registryId: string) => void
}) => {
    const {data: registrations} = useRegistrations(panonId);
    const {data: registries} = useRegistries(registrations.map(r => r.registryId));
    const activeRegistry = registries.find(r => r.id === activeRegistryId);
    return (
        <Widget displayName={"Registriert bei " + activeRegistry?.name}>
            {registries.length > 1
                ? <>
                    <Typography>Auch registriert bei:</Typography>
                    <List>
                        {registries.filter(r => r.id !== activeRegistryId).map(r => <ListItem key={r.id} disablePadding>
                            <ListItemButton onClick={() => onClick(r.id)}>
                                {r.name}
                            </ListItemButton>
                        </ListItem>)}
                    </List>
                </>
                : null}
        </Widget>
    );
};

const RegisteredAnimalDetails = () => {
    const nav = useNav();
    const {error, success} = useGlobalSnackbarStore(state => state);
    const {panonId, registryId} = useParams<{ registryId: string, panonId: string }>();

    const {animal} = useSuspenseAnimal(panonId);
    const {data: registration} = useRegistration(registryId, panonId);
    const {data: wardenRegistryIds} = useWardenRegistries();

    if (!registration || !animal) {
        return <LinearProgress/>;
    }

    const isRegistryWarden = wardenRegistryIds.includes(registration.registryId);

    const handleBaseDataEdit = async (animalBaseData: AnimalBaseData) => {
        const editData: RegistrationsController$RegistrationEditJson = {
            breed: animalBaseData.breed,
            dateOfBirth: animalBaseData.dateOfBirth,
            eyeColor: registration.phenotype.eyeColor,
            herdCode: animalBaseData.herdCode,
            name: animalBaseData.name,
            primaryColor: animalBaseData.fiberColor,
            secondaryColors: registration.phenotype.secondaryColors,
            sex: animalBaseData.sex
        };
        try {
            await RegistrationService.editRegistration(registration, editData);
            success("Daten erfolgreich aktualisiert!");
        } catch (e) {
            error("Aktualisierung fehlgeschlagen!");
        }
    }

    return (
        <FlexStack key={registration.id}>
            <BaseData
                onSubmit={handleBaseDataEdit}
                editable={isRegistryWarden}
                initialBaseData={transformFromRegistration(registration)}/>

            <RegistrationsWidget
                activeRegistryId={registryId}
                onClick={id => nav.push(RouteService.REGISTERED_ANIMAL_DETAILS, {panonId, registryId: id})}
                panonId={panonId}/>

            <OwnerWidget ownerId={animal.ownerId} panonId={animal.panonIdentifier.id}/>

            <PedigreeWidget
                isRegistered
                animal={animal}
                isRegistryWarden={isRegistryWarden}
                expandedRedirectUrl={RouteService.expand(RouteService.REGISTERED_ANIMAL_DETAILS, {panonId, registryId})}/>
        </FlexStack>
    );
};

export default RegisteredAnimalDetails
