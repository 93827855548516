// @flow
import * as React from 'react';
import {FC, FunctionComponent, ReactElement, useState} from 'react';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    LinearProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
    SvgIcon,
    Typography
} from "@mui/material";
import {useHistory, useParams} from "react-router-dom";
import {PregnancyService} from "../../../services/pregnancy-service";
import AnimalCard from "../../AnimalCard";
import {DateTime} from "luxon";
import {TreatmentListItem} from '../TreatmentList/treatment-list-item';
import DateTimeService from "../../../services/date-time-service";
import DatePickerDialog from "../../Common/date-picker-dialog";
import TreatmentService from "../../../services/treatment-service";
import {PregnancyDetailJson, TreatmentListJson} from "../../../api/generated/medical-rest";
import {ReactComponent as MatingDateSvg} from "../../../icons/mating_date.svg"
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SpeedDial from "@mui/material/SpeedDial";
import RouteService from "../../../services/route-service";
import {AnimalCreateLocationState} from "../../../pages/animal-create";
import {getPregnancyDurationDays, getPregnancyState, PregnancyState} from "./pregnancy-utils";
import {Cake, Description, EventBusy, Flare, SportsScoreOutlined} from "@mui/icons-material";
import ConfirmPregnancyDialog from "./confirm-pregnancy-dialog";
import {ErrorBox, InfoBox} from "../../Common/alerts";
import {useQuery} from "@tanstack/react-query";

interface PregnancyDetailsProps {
    pregnancyId?: string
}

interface ChronologyItem {
    dateTime: DateTime,
    component: ReactElement,
}

interface PregnancyListItemProps {
    eventDate: DateTime,
    primary: string,
    icon: ReactElement,
}

const treatmentToChronologyItem = (t: TreatmentListJson): ChronologyItem => ({
    dateTime: t.timestamp,
    component: <TreatmentListItem editable={false} key={t.id} treatment={t}/>
});

const makeChronologyItem = (dateTime: DateTime, attrs: {
    key: string,
    text: string,
    icon: ReactElement
}): ChronologyItem => ({
    dateTime: dateTime,
    component: <ChronologyListItem key={attrs.key}
                                   eventDate={dateTime}
                                   primary={attrs.text}
                                   icon={attrs.icon}/>
});

const pregnancyChronologyItems = (pregnancy: PregnancyDetailJson): ChronologyItem[] => {
    const chronologyItems: ChronologyItem[] = [];

    if (pregnancy.startDate) {
        chronologyItems.push(makeChronologyItem(pregnancy.startDate, {
            key: 'pStart',
            text: 'Trächtigkeitsbeginn',
            icon: <Flare/>
        }));
    }

    if (pregnancy.calculatedEndDate) {
        chronologyItems.push(makeChronologyItem(pregnancy.calculatedEndDate, {
            key: 'pCalcEnd',
            text: 'Errechneter Geburtstermin',
            icon: <SportsScoreOutlined/>
        }));
    }

    if (pregnancy.actualEndDate) {
        if (pregnancy.note.toLowerCase().startsWith("outcome: miscarriage")) {
            chronologyItems.push(makeChronologyItem(pregnancy.actualEndDate, {
                key: 'pActualEnd',
                text: 'Fehlgeburt',
                icon: <EventBusy/>
            }));
        } else if (pregnancy.startDate) {
            chronologyItems.push(makeChronologyItem(pregnancy.actualEndDate, {
                key: 'pActualEnd',
                text: 'Geburt',
                icon: <Cake/>
            }));
        } else {
            chronologyItems.push(makeChronologyItem(pregnancy.actualEndDate, {
                key: 'pActualEnd',
                text: 'Erfolglose Deckung',
                icon: <EventBusy/>
            }));
        }
    }

    if (pregnancy.matingDate) {
        chronologyItems.push(makeChronologyItem(pregnancy.matingDate, {
            key: 'pMating',
            text: 'Deckung',
            icon: <SvgIcon><MatingDateSvg/></SvgIcon>
        }));
    }

    return chronologyItems;
}

const maxDateTime = (a: DateTime, b: DateTime): DateTime => a > b ? a : b;

const usePregnancyDetails = (pregnancyId: string | undefined) => {
    const [chronology, setChronology] = useState<ChronologyItem[]>([]);

    const {data, isLoading, isError, refetch} = useQuery({
        queryKey: ['pregnancy-details', pregnancyId],
        enabled: !!pregnancyId,
        queryFn: async () => {
            if (!pregnancyId) {
                return null;
            }

            const pregnancy = await PregnancyService.findPregnancyById(pregnancyId);
            const [dam, sire] = await Promise.all([
                PregnancyService.findMother(pregnancy),
                PregnancyService.findFather(pregnancy),
            ]);

            if (!dam) {
                return null;
            }

            const filterEndDate: DateTime = pregnancy.actualEndDate || maxDateTime(pregnancy.calculatedEndDate, DateTime.now());
            const treatments = await TreatmentService.loadTreatments({
                animalId: dam.id,
                interval: {start: pregnancy.startDate, end: filterEndDate},
                type: undefined as unknown as string
            })

            const chronology: ChronologyItem[] = treatments
                .map(t => treatmentToChronologyItem(t))
                .concat(pregnancyChronologyItems(pregnancy))
                .sort(DateTimeService.sortByDateASC("dateTime"));

            setChronology(chronology);

            return {
                pregnancy,
                dam,
                sire,
            };
        }
    });

    return {
        isLoading,
        isError,
        refetch,
        setChronology,
        chronology,
        ...data,
    }
};

const ChronologyListItem: FunctionComponent<PregnancyListItemProps> = ({eventDate, primary, icon}) => {
    return <ListItem>
        <ListItemAvatar>
            <Avatar>
                {icon}
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary={primary} secondary={eventDate.toLocaleString()}/>
    </ListItem>;
}

const getPregnancySuffix = (pregnancyState: PregnancyState | null | undefined) => {
    switch (pregnancyState) {
        case PregnancyState.COMPLETED: return '(abgeschlossen)';
        case PregnancyState.UNSUCCESSFUL: return '(erfolglos)';
        case PregnancyState.PREGNANT: return '(trächtig)';
        case PregnancyState.MATED: return '(gedeckt)';
        default: return '';
    }
};

const PregnancyDetails: FC<PregnancyDetailsProps> = ({pregnancyId}) => {
    const history = useHistory();
    const pregId = useParams<PregnancyDetailsProps>().pregnancyId || pregnancyId;
    const [openBirthDateDialog, setOpenBirthDateDialog] = useState(false);
    const [openMiscarriageDateDialog, setOpenMiscarriageDateDialog] = useState(false);
    const [openConfirmPregnancyDialog, setOpenConfirmPregnancyDialog] = useState(false);
    const [openUnsuccessfulMatingDialog, setOpenUnsuccessfulMatingDialog] = useState(false);
    const [speedDialOpen, setSpeedDialOpen] = useState<boolean>(false);

    const {
        isLoading,
        isError,
        refetch,
        pregnancy,
        dam,
        sire,
        chronology,
        setChronology
    } = usePregnancyDetails(pregId);

    if (isLoading) {
        return <LinearProgress/>;
    }

    if (isError) {
        return <ErrorBox>Fehler beim Laden der Trächtigkeit.</ErrorBox>;
    }

    if (!pregnancy || !dam) return null;

    const handleSaveBirthDate = async (date: DateTime) => {
        if (pregId) {
            setOpenBirthDateDialog(false);
            const animalCreateLocationState: AnimalCreateLocationState = {
                createForHerd: true,
                pregnancy: {...pregnancy, id: pregId, actualEndDate: date}
            };
            history.push(RouteService.ANIMAL_CREATE, animalCreateLocationState)
        } else {
            setOpenBirthDateDialog(false);
        }
    }

    const handleSaveMiscarriage = async (date: DateTime) => {
        if (pregId) {
            await PregnancyService.recordMiscarriage(pregId, date);
            setChronology(chronology => [...chronology, makeChronologyItem(date, {
                key: 'pActualEnd',
                text: 'Fehlgeburt',
                icon: <EventBusy/>
            })]);
        }
        setOpenMiscarriageDateDialog(false);
        await refetch();
    }

    const handleConfirmPregnancy = async (startDate: DateTime, calculatedEndDate: DateTime) => {
        if (pregId) {
            await PregnancyService.confirmPregnancy(pregId, {startDate, calculatedEndDate});
            setChronology(chronology => [
                ...chronology,
                makeChronologyItem(startDate, {
                    key: 'pStart',
                    text: 'Trächtigkeitsbeginn',
                    icon: <Flare/>
                }),
                makeChronologyItem(calculatedEndDate, {
                    key: 'pCalcEnd',
                    text: 'Errechneter Geburtstermin',
                    icon: <SportsScoreOutlined/>
                })]);
        }
        setOpenConfirmPregnancyDialog(false);
        await refetch();
    }

    const handleUnsuccessfulMating = async (date: DateTime) => {
        if (pregId) {
            await PregnancyService.recordBirth(pregId, date); // TODO this works, but is semantically awkward
            setChronology(chronology => [...chronology, makeChronologyItem(date, {
                key: 'pActualEnd',
                text: 'Erfolglose Deckung',
                icon: <EventBusy/>
            })]);
        }
        setOpenUnsuccessfulMatingDialog(false);
        await refetch();
    }

    const sireCard = sire
        ? <AnimalCard data={sire} withImage={true}/>
        : <Card>
            <CardContent>
                <InfoBox>Kein Deckhengst eingetragen</InfoBox>
            </CardContent>
        </Card>;

    const pregnancyState = getPregnancyState(pregnancy);
    const pregnancySuffix = getPregnancySuffix(pregnancyState);

    return (
        <Box>
            <DatePickerDialog title="Geburtsdatum" open={openBirthDateDialog} onSave={handleSaveBirthDate}
                              onCancel={() => setOpenBirthDateDialog(false)}/>
            <DatePickerDialog title="Datum der Fehlgeburt" open={openMiscarriageDateDialog}
                              onSave={handleSaveMiscarriage}
                              onCancel={() => setOpenMiscarriageDateDialog(false)}/>
            <ConfirmPregnancyDialog title="Trächtigkeitsbeginn"
                                    open={openConfirmPregnancyDialog}
                                    onSave={handleConfirmPregnancy}
                                    onCancel={() => setOpenConfirmPregnancyDialog(false)}/>
            <DatePickerDialog title="Erfolglose Deckung"
                              open={openUnsuccessfulMatingDialog}
                              onSave={handleUnsuccessfulMating}
                              onCancel={() => setOpenUnsuccessfulMatingDialog(false)}/>
            <Stack spacing={3} justifyContent="flex-start" alignItems="left">
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Stack justifyContent="flex-start" alignItems="left">
                            <Typography align="center" variant="h4">Stute</Typography>
                            <AnimalCard data={dam} withImage={true}/>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack justifyContent="flex-start" alignItems="left">
                            <Typography align="center" variant="h4">Deckhengst</Typography>
                            {sireCard}
                        </Stack>
                    </Grid>
                </Grid>
                {!!pregnancy.startDate &&
                    <Stack justifyContent="flex-start" alignItems="left">
                        <Typography variant="h4" align="center">Tragezeit</Typography>
                        <Typography align="center">
                            {getPregnancyDurationDays(pregnancy)} Tage
                        </Typography>
                    </Stack>}
                <Stack>
                    <Typography variant="h4" align="center">Trächtigkeitsverlauf {pregnancySuffix}</Typography>
                    <List>
                        {chronology.map(c => c.component)}

                        {!!pregnancy.note && <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <Description/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Notiz" secondary={pregnancy.note}/>
                        </ListItem>}
                    </List>
                </Stack>
            </Stack>
            {[PregnancyState.MATED, PregnancyState.PREGNANT].includes(pregnancyState!) && <SpeedDial
                ariaLabel="Schnellzugriff"
                sx={{position: 'fixed', bottom: 16, right: 16}}
                icon={<SpeedDialIcon/>}
                onClose={() => setSpeedDialOpen(false)}
                onOpen={() => setSpeedDialOpen(true)}
                open={speedDialOpen}
            >
                {pregnancyState === PregnancyState.PREGNANT &&
                    <SpeedDialAction
                        key="recordBirth"
                        tooltipTitle="Geburt"
                        tooltipOpen
                        icon={<Cake/>}
                        onClick={() => setOpenBirthDateDialog(true)}
                    />}
                {pregnancyState === PregnancyState.PREGNANT &&
                    <SpeedDialAction
                        key="recordMiscarriage"
                        tooltipTitle="Fehlgeburt"
                        tooltipOpen
                        icon={<EventBusy/>}
                        onClick={() => setOpenMiscarriageDateDialog(true)}
                    />}
                {pregnancyState === PregnancyState.MATED &&
                    <SpeedDialAction
                        key="confirmPregnancy"
                        tooltipTitle="Trächtigkeit bestätigen"
                        tooltipOpen
                        icon={<Flare/>}
                        onClick={() => setOpenConfirmPregnancyDialog(true)}
                    />}
                {pregnancyState === PregnancyState.MATED &&
                    <SpeedDialAction
                        key="unsuccessfulMating"
                        tooltipTitle="Erfolglose Deckung"
                        tooltipOpen
                        icon={<EventBusy/>}
                        onClick={() => setOpenUnsuccessfulMatingDialog(true)}
                    />}
            </SpeedDial>}

        </Box>
    );
};

export default PregnancyDetails;
