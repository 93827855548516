import {AnimalRecordStatus} from "../../../api/generated/herd-animal";
import {AnimalService} from "../../../services/animal-service";
import {useSuspenseQuery} from "@tanstack/react-query";

export const useHerd = (recordStatus: AnimalRecordStatus) => {
    const {data} = useSuspenseQuery({
        queryKey: ['herd', recordStatus],
        queryFn: async () => await AnimalService.loadHerd('all'),
        select: animals => animals.filter(a => a.recordStatus === recordStatus),
    });

    return {herd: data};
};
