// @flow
import React, {FunctionComponent} from 'react';
import AnimalSearchResult from "../../api/dtos/animal-search-result";
import RouteService from "../../services/route-service";
import AnimalSearch from "../Common/animal-search";
import {Typography} from "@mui/material";
import {useNav} from "../Common/hooks/use-nav";

const RegistrationsSearchPage: FunctionComponent = () => {

    const nav = useNav();

    const handleCardClick = (animal: AnimalSearchResult) => {
        const [registry] = animal.registries;
        nav.push(RouteService.REGISTERED_ANIMAL_DETAILS, {
            registryId: registry?.id,
            panonId: animal.panonId.id,
        });
    }

    return (
        <AnimalSearch handleResultClick={handleCardClick} searchHerdAndPedigree={false} renderOnEmptyResult={<Typography> keine Ergebnisse</Typography>} />
    );
};

export default RegistrationsSearchPage