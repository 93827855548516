// @flow
import React, {Fragment, FunctionComponent, PropsWithChildren} from 'react';
import {JsonAnimal} from "../../api/generated/rest-dto";
import {Sex, SexValues} from "../../api/generated/herd-animal";

type Props = PropsWithChildren<{
    requiredSex: Sex
    animal?: JsonAnimal,
    animalSex?: Sex
}>;

const RenderOnSex: FunctionComponent<Props> = ({requiredSex, animal, animalSex, children}) => {
    const actualSex: Sex = animal ? animal.sex : animalSex || SexValues.UNKNOWN;
    return (requiredSex === actualSex) ? <Fragment>{children}</Fragment> : null;
};

export default RenderOnSex