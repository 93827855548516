type RouteParams = Record<string, string>;

const expandRoute = (routeTemplate: string, parameters: RouteParams): string => {
    let expanded = routeTemplate;
    Object.entries(parameters).forEach((param) => {
        expanded = expanded.replace(`:${param[0]}`, param[1]);
    });
    return expanded;
}

const RouteService = {
    ADMIN_LINK_ACCOUNT_WITH_BREEDER_EDITOR: "/admin/accounts/link/:breederName",
    ADMIN_LINK_ACCOUNT_WITH_BREEDER: "/admin/accounts/link",
    BREEDER_BASE_DATA: "/breeder/basedata",
    BREEDER_SIGNUP: "/breeder/signup",
    BREEDER_DETAILS: "/breeder/:breederId",
    BREEDERS_LIST: "/breeders",
    HERD_GROUP: "/herd/view/:groupId/:recordStatus",
    HERD_PREGNANCIES_LIST: "/herd/pregnancies/list",
    REGISTERED_ANIMAL_DETAILS: "/registry/:registryId/animal/:panonId",
    ANIMAL_DETAILS:"/animal/view/:panonId",
    ANIMAL_DETAILS_PDF:"/animal/view/:panonId/pdf",
    ANIMAL_CHANGE_ANCESTRY:"/animal/changeAncestry/:panonId/",
    ANIMAL_TRANSFER_OWNERSHIP: "/animal/:panonId/ownership/transfer",
    ANIMAL_CREATE:"/animal/create",
    ANIMAL_REGISTRY_APPLICATION_SELECT: "/animal/:panonId/registry/application",
    TAGS_EDIT: "/animal/:panonId/tags/edit",
    TREATMENT_EDIT: "/animal/:animalId/treatment/edit",
    MATING_CREATE: "/animal/mating/create",
    PREGNANCY_CREATE: "/animal/pregnancy/create",
    PREGNANCY_DETAILS: "/animal/pregnancy/:pregnancyId",
    REGISTRY_ANIMAL_LIST: "/registry/:registryId/animals",
    MEMBER_REGISTRIES: "/registry/member/list",
    REGISTRY_JOIN: "/registry/join",
    REGISTRY_SEARCH: "/registry/find",
    REGISTRY_MY_REGISTRATIONS_LIST: "/registry/registrations",
    REGISTRY_ANIMAL_SEARCH: "/registry/animal/find",
    REGISTRY_ANIMAL_REVIEW_COMPARE_SIMILARITIES: "/registry/review/:reviewedAnimalPanonId/similarities/:similarAnimalPanonId",
    REGISTRY_ANIMAL_REVIEW_LIST: "/registry/review",
    REGISTRY_ANIMAL_REVIEW: "/registry/review/:id",
    REGISTRY_ADMINISTRATION_CREATE: "/registry/administration/create",
    REGISTRY_ADMINISTRATION_DETAILS: "/registry/administration/:id/details",
    REGISTRY_ADMINISTRATION_ADD_WILDCARD_USERTYPE: "/registry/administration/:id/add/:userType",
    REGISTRY_ADMINISTRATION_ADD_ADMIN: "/registry/administration/:id/add/admin",
    REGISTRY_ADMINISTRATION_ADD_WARDEN: "/registry/administration/:id/add/warden",
    REGISTRY_ADMINISTRATION_ADD_MEMBER: "/registry/administration/:id/add/member",
    REGISTRY_LIST: "/registry",
    TASK_NAVIGATE: "/:type/view/:subjectId",
    ADD_HELP_ARTICLE: "/help/add",
    EDIT_HELP_ARTICLE: "/help/edit/:articleId",
    HELP: "/help",
    HOME: "/",

    CATCH_ALL: "*",
    expand: expandRoute,
}

export default RouteService;