// @flow
import React, {FunctionComponent} from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {DateTime} from "luxon";
import {StaticDatePicker} from "@mui/x-date-pickers";

type Props = {
    open?: boolean;
    date?: DateTime;
    onSave: (date: DateTime) => void;
    onCancel: () => void;
    title: string
};
const DatePickerDialog: FunctionComponent<Props> = ({open = false, date = DateTime.now(), onSave, onCancel, title}) => {

    const [value, setValue] = React.useState(date ? date : DateTime.now());

    const onPickerChange = (selected: DateTime | null) => setValue(selected ? selected : DateTime.now());

    return (
        <Dialog open={open} onClose={onCancel} aria-labelledby='form-dialog-title'>
            <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
            <DialogContent>
                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    value={value}
                    onChange={onPickerChange}
                 />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color='primary'>
                    Cancel
                </Button>
                <Button onClick={()=> onSave(value)} color='primary'>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DatePickerDialog
