import {
    RegistrationJson,
    RegistrationRequestApi,
    RegistrationRequestController$PendingRequestJson,
    RegistrationsApi,
    RegistrationsController$RegistrationEditJson,
    RequestRegistrationApi
} from "../api/generated/registry-rest";
import ApiConfig from "../api/config/api-config";
import {RegistryDto as RegistryJson} from "../api/generated/registry-service";
import {JsonAnimal} from "../api/generated/rest-dto";
import dateConverter from "./date-converter";


export interface IRegistrationService {
    findRegistrationsByPanonId(panonId: string): Promise<RegistrationJson[]>;

    findPendingRequestsByPanonId(panonId: string): Promise<RegistrationRequestController$PendingRequestJson[]>;

    findPendingRequestsByRegistryId(registryId: string): Promise<RegistrationRequestController$PendingRequestJson[]>;

    getPendingRequest(requestId: string): Promise<RegistrationRequestController$PendingRequestJson>;

    submitRequest(registry: RegistryJson, animal: JsonAnimal): Promise<string>;

    submitRequestWithIds(registryId: string, panonId: string): Promise<string>;

    registrationForAnyRegistryExists(registryIds: string[], panonId: string): Promise<boolean>;

    findRegistrationsByPanonIds(panonId: string[]): Promise<Map<string, RegistrationJson[]>>;

    loadRegistration(registryId: string, panonId: string): Promise<RegistrationJson>;

    editRegistration(registration: RegistrationJson, editData: RegistrationsController$RegistrationEditJson): Promise<void>;
}

export class RegistrationServiceImpl implements IRegistrationService {
    private readonly requestRegistrationApi: RequestRegistrationApi;
    private readonly registrationRequestApi: RegistrationRequestApi;
    private readonly registrationsApi: RegistrationsApi;

    constructor(requestRegistrationApi: RequestRegistrationApi, registrationRequestApi: RegistrationRequestApi, registrationsApi: RegistrationsApi) {
        this.requestRegistrationApi = requestRegistrationApi;
        this.registrationRequestApi = registrationRequestApi;
        this.registrationsApi = registrationsApi;
    }

    async loadRegistration(registryId: string, panonId: string) {
        const result = await registrationsApi.details(panonId, registryId);
        return result.item;
    }

    async editRegistration(registration: RegistrationJson, registrationEditDto: RegistrationsController$RegistrationEditJson): Promise<void> {
        registrationEditDto.dateOfBirth = dateConverter.transformToISODateFromDateTime(registrationEditDto.dateOfBirth);
        const updateResult = await registrationsApi.edit(registration.panonIdentifier, registration.registryId, registrationEditDto);
        if(updateResult.info !== "updated") throw new Error("Edit Registration failed!");
    }

    async findRegistrationsByPanonIds(panonId: string[]) {
        const animalRegistrationPromises = panonId.map(panonId => this.findRegistrationsByPanonId(panonId));
        const groupedRegistrations = new Map<string, RegistrationJson[]>();
        (await Promise.all(animalRegistrationPromises))
            .forEach(registrations => {
                if (registrations.length > 0) {
                    groupedRegistrations.set(registrations[0].panonIdentifier, registrations)
                }
            });
        return groupedRegistrations;
    }

    async findRegistrationsByPanonId(panonId: string): Promise<RegistrationJson[]> {
        this.validateNotEmpty("panonId", panonId);
        return (await this.registrationsApi.listRegistrations(panonId)).items;
    }

    async findPendingRequestsByPanonId(panonId: string): Promise<RegistrationRequestController$PendingRequestJson[]> {
        return (await this.registrationRequestApi.list(undefined, undefined, panonId)).items;
    }

    async findPendingRequestsByRegistryId(registryId: string): Promise<RegistrationRequestController$PendingRequestJson[]> {
        return (await this.registrationRequestApi.list(registryId)).items;
    }

    async getPendingRequest(requestId: string): Promise<RegistrationRequestController$PendingRequestJson> {
        return (await this.registrationRequestApi.get(requestId)).item;
    }

    async submitRequest(registry: RegistryJson, animal: JsonAnimal): Promise<string> {
        return this.submitRequestWithIds(registry.id, animal.panonIdentifier.id);
    }

    async submitRequestWithIds(registryId: string, panonId: string): Promise<string> {
        return (await this.requestRegistrationApi.submitRequest(registryId, panonId)).id;
    }

    async registrationForAnyRegistryExists(registryIds: string[], panonId: string): Promise<boolean> {
        const registrations = await this.registrationsApi.listRegistrations(panonId);
        return registrations.items.find(registration => registryIds.includes(registration.registryId)) !== undefined;
    }


    private validateNotEmpty(fieldName:string, value: string){
        if(!value) throw new Error(`${fieldName} must not be empty!`);
    }

}

const apiConfig = new ApiConfig();
const requestRegistrationApi = new RequestRegistrationApi(apiConfig);
const registrationRequestApi = new RegistrationRequestApi(apiConfig);
const registrationsApi = new RegistrationsApi(apiConfig);

export const RegistrationService: IRegistrationService = new RegistrationServiceImpl(requestRegistrationApi, registrationRequestApi, registrationsApi);