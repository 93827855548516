// @flow
import {Button, TextField, Typography} from '@mui/material';
import React, {FunctionComponent, useState} from 'react';
import {useParams} from "react-router-dom";
import {BreederService} from "../services/breeder-service";
import {useGlobalSnackbarStore} from "../stores/global-snackbar-store";
import {LinkAccountDto} from "../api/generated/breeder-controller";
import RouteService from "../services/route-service";
import {getErrorMessage} from "../errors/error-utils";
import FlexStack from '../components/Common/flex-stack';
import { useMutation } from '@tanstack/react-query';


type AdminAccountsLinkEditorProps = {
    breederName?: string
};

const AdminAccountsLinkEditor: FunctionComponent<AdminAccountsLinkEditorProps> = ({breederName}) => {

    const breeder = useParams<AdminAccountsLinkEditorProps>().breederName || breederName;
    const {error, success} = useGlobalSnackbarStore((state) => state);

    const [accountName, setAccountName] = useState<string>("");

    const breederLinkMutation = useMutation({
        mutationFn: (linkAccountDto: LinkAccountDto) => BreederService.linkAccounts(linkAccountDto),
        onSuccess: (data, variables, context) => success("successfully linked accounts", RouteService.ADMIN_LINK_ACCOUNT_WITH_BREEDER),
        onError:(e, variables, context) => error("Account linking failed with the following error: " + getErrorMessage(e))
    });

    const handleSaveLinkClick = () => {
        if (breeder) {
            breederLinkMutation.mutate({breederName: breeder, accountName});
        }
    }

    const updateAccountName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAccountName(event.target.value);
    }

    return (
        <FlexStack>
            <Typography variant="h4">Link Herstellen</Typography>
            <Typography variant="body1">Züchter: {breeder}</Typography>
            <TextField label="AccountName" value={accountName} onChange={updateAccountName}/>
            <Button variant="contained" onClick={handleSaveLinkClick}>Speichern</Button>
        </FlexStack>
    );
};

export default AdminAccountsLinkEditor