import {OwnershipApi} from "../api/generated/animal-controller";
import ApiConfig from "../api/config/api-config";
import {JsonTransferExternalOwnership, JsonTransferOwnership} from "../api/generated/rest-dto";

let ownershipApi = new OwnershipApi(new ApiConfig());

export interface IOwnershipService {

    transferOwnership(newOwnership: JsonTransferOwnership): Promise<void>

    transferOwnershipToExternal(externalOwnership: JsonTransferExternalOwnership): Promise<void>
}

class OwnershipServiceImpl implements IOwnershipService {
    private _ownershipApi: OwnershipApi;

    constructor(ownershipApi: OwnershipApi) {
        this._ownershipApi = ownershipApi;
    }

    async transferOwnership(newOwnership: JsonTransferOwnership): Promise<void> {
        await this._ownershipApi.transferOwnership(newOwnership)
    }
    async transferOwnershipToExternal(externalOwnership: JsonTransferExternalOwnership): Promise<void> {
        await this._ownershipApi.transferOwnershipToExternal(externalOwnership);
    }
}

export const OwnershipService: IOwnershipService = new OwnershipServiceImpl(ownershipApi);
