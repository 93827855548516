// @flow
import React, {useCallback, useState} from 'react';
import FlexStack from "../../Common/flex-stack";
import {useTranslation} from "react-i18next";
import {BreederSearchResult} from "../../../api/generated/herd-breeder";
import {SearchField} from "../../Common";
import {FilteredBreeders} from "../../Breeder/filtered-breeders";
import {Button, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
import ConfirmDialog from "../../Common/confirm-dialog";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {useSuspenseAnimal} from "../../Common/hooks/use-animal";
import {useMutation} from "@tanstack/react-query";
import {useGlobalSnackbarStore} from "../../../stores/global-snackbar-store";
import {OwnershipService} from "../../../services/ownership-service";
import {JsonTransferExternalOwnership, JsonTransferOwnership} from "../../../api/generated/rest-dto";
import RouteService from "../../../services/route-service";
import {AnimalRecordStatusValues} from "../../../api/generated/herd-animal";

const OwnershipTransferConfirmDialog = ({open, onClose, onOk}: {
    open: boolean,
    onClose: () => void,
    onOk: () => void
}) => {
    const {t} = useTranslation();
    const [confirmText, setConfirmText] = useState("");


    const handleClose = () => {
        setConfirmText("");
        onClose();
    }

    const confirmString = t('common.confirmString');

    return (
        <ConfirmDialog
            title={t('breeders.ownerChangeConfirmTitle')}
            open={open}
            onOk={onOk}
            onClose={handleClose}
            okButtonDisabled={confirmText !== t('common.confirmString')}
        >
            <Typography>{t('breeders.ownerChangeConfirmNotice')}</Typography>
            <Typography>{t('breeders.ownerRequireConfirmString',
                {
                    confirmString
                }
            )}</Typography>
            <TextField onChange={(e) => setConfirmText(e.target.value)}
                       label={t('breeders.ownerRequireConfirmString', {confirmString})}/>
        </ConfirmDialog>
    )
}


const ExternalBreeder = ({onContinue}: { onContinue: (breederName: string) => void }) => {
    const {t} = useTranslation();
    const [extBreederName, setExtBreederName] = useState("")

    const handleBreederNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setExtBreederName(e.target.value)
    const handleContinueClick = () => onContinue(extBreederName);

    return (
        <FlexStack>
            <Typography>{t("breeders.notFound")}</Typography>
            <TextField value={extBreederName} onChange={handleBreederNameChange} variant="outlined"
                       label={t("breeders.enterExternal")}/>
            <Button variant="contained" color="primary" onClick={handleContinueClick}>{t('common.continue')}</Button>
        </FlexStack>
    )
}

const OwnershipTransfer = ({match}: RouteComponentProps<{ panonId: string }>) => {
    const {panonId} = match.params;
    const {t} = useTranslation();
    const [searchTerm, setSearchTerm] = useState('')
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [transferTo, setTransferTo] = useState<BreederSearchResult | string>("")

    const {success} = useGlobalSnackbarStore((state) => state);

    const useAnimal = useSuspenseAnimal(panonId);

    const ownerTransferMutation = useMutation({
        mutationKey: ["TransferAnimalOwnership"],
        mutationFn: (newOwnership: JsonTransferOwnership) => OwnershipService.transferOwnership(newOwnership),
        onSuccess: () => success(t("breeders.ownershipTransferSuccess"), RouteService.expand(RouteService.HERD_GROUP, {groupId: "all", recordStatus: AnimalRecordStatusValues.HERD}))
    })

    const externalOwnerTransferMutation = useMutation({
        mutationKey: ["TransferAnimalOwnershipToExternal"],
        mutationFn: (externalOwnership: JsonTransferExternalOwnership) => OwnershipService.transferOwnershipToExternal(externalOwnership),
        onSuccess: () => success(t("breeders.ownershipTransferSuccess"), RouteService.expand(RouteService.HERD_GROUP, {groupId: "all", recordStatus: AnimalRecordStatusValues.HERD}))
    })

    const performSearch = useCallback(async (searchTerm: string): Promise<BreederSearchResult[]> => {
        setSearchTerm(searchTerm);
        return []; //we don't show suggestions on the searchfield here, so [] is returned
    }, []);

    const handleBreederSelected = (breeder: BreederSearchResult | string) => {
        setTransferTo(breeder);
        setConfirmDialogOpen(true);

    };

    const handleOwnershipTransferConfirm = () => {
        if (typeof transferTo === "string") {
            externalOwnerTransferMutation.mutate({
                externalBreederName: transferTo,
                panonId: panonId
            })
        } else {
            ownerTransferMutation.mutate({
                breederId: transferTo.id,
                panonId: panonId
            })
        }
    }

    return (
        <FlexStack>
            <Typography variant="h4">{t("breeders.pickNewOwner", {animal: useAnimal.animal?.name})}</Typography>
            <SearchField<BreederSearchResult> label={t("breeders.filter")}
                                              findSuggestions={performSearch}
                                              value={null}
            />
            <FilteredBreeders onBreederClick={handleBreederSelected}
                              searchTerm={searchTerm}
                              emptyResultRender={
                                  <ExternalBreeder onContinue={handleBreederSelected}/>
                              }/>
            <OwnershipTransferConfirmDialog
                onOk={handleOwnershipTransferConfirm}
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}/>
        </FlexStack>
    );
};

export default withRouter(OwnershipTransfer)